import {
  BagelCodeSection,
  DefaultSection,
  LLCSection,
  PipaSection,
  WhispersSection
} from '@appcharge/shared-ui';

import { ESectionViewModel } from '../constants/enums';

export const mapModelSection: Partial<Record<ESectionViewModel, any>> = {
  [ESectionViewModel.DEFAULT]: DefaultSection,
  [ESectionViewModel.BAGELCODE]: BagelCodeSection,
  [ESectionViewModel.PIPA]: PipaSection,
  [ESectionViewModel.WHISPERS]: WhispersSection,
  [ESectionViewModel.LLC]: LLCSection
};

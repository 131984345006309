import { useEffect, useState } from 'react';

import { Alert, Box, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { DateRange } from '@mui/x-date-pickers-pro';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import dayjs, { Dayjs } from 'dayjs';

import AcInput from 'components/AcInput/AcInput';

import AcSelect from '../AcSelect/AcSelect';
import ActionButton from '../ActionButton/ActionButton';

import { AcDatePickerProps } from './AcDatePicker.types';

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: 'Today',
    getValue: () => {
      const today = dayjs().startOf('day');
      const endOfDay = dayjs().endOf('day');
      return [today, endOfDay];
    }
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, 'days'), today];
    }
  },
  {
    label: 'Last 30 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(30, 'days'), today];
    }
  },
  { label: 'Reset', getValue: () => [null, null] }
];

export default function AcDatePicker({
  onApply,
  onClose,
  isHourPicker,
  isHourPickerInDays = false,
  minDate,
  onApplyButtonText = 'Apply',
  disableFuture = true,
  editRangeDatesValues,
}: AcDatePickerProps) {
  const [isHoursAvailable, setIsHoursAvailable] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
  const [hours, setHours] = useState<[string, string]>(['00:00', '23:59']);
  const [isApplyDisabled, setIsApplyDisabled] = useState(true);
  const [duration, setDuration] = useState<number | null>(null);
  const [notes, setNotes] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!hours[0] || hours[1]) setDuration(null);
    const fromTime = dayjs(hours[0], 'HH:mm');
    const toTime = dayjs(hours[1], 'HH:mm');
    // Calculate the difference in hours
    if (toTime.isAfter(fromTime)) {
      const diffInHours = toTime.diff(fromTime, 'hour', true);
      setDuration(Math.round(diffInHours));
    } else {
      setDuration(null); // Set duration to null if invalid range
    }
  }, [hours]);

  useEffect(() => {
    if (editRangeDatesValues) {
      setDateRange(editRangeDatesValues); // Set the initial values when editing
    }
  }, [editRangeDatesValues]);

  useEffect(() => {
    const [startDate, endDate] = dateRange;
    const startHour = dayjs(hours[0], 'HH:mm');
    const endHour = dayjs(hours[1], 'HH:mm');

    if (startHour.isAfter(endHour)) {
      setErrorMessage('Start time cannot be before end time.');
      return;
    }
    if (hours[0] === hours[1]) {
      setErrorMessage('An appearance must be with a duration > 0.');
      return;
    }
    if (startDate && endDate) {
      const diffDays = endDate.diff(startDate, 'day');
      if (diffDays > 100) {
        setErrorMessage("You can't select a time range that exceeds 100 days.");
        return;
      }
    }

    setErrorMessage(null);
  }, [hours, dateRange]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDateRangePicker
        slotProps={{
          shortcuts: {
            items: shortcutsItems
          },
          actionBar: { actions: [] }
        }}
        disableFuture={disableFuture}
        minDate={minDate || undefined}
        value={dateRange}
        onChange={(v) => {
          setDateRange(v);

          if ((!!v[0] && !v[1]) || (!!v[1] && !v[0])) {
            setIsApplyDisabled(true);
          } else {
            setIsApplyDisabled(false);
          }

          if (
            (!!v[0] && !v[1]) ||
            (!!v[0] &&
              !!v[1] &&
              v[0].toDate().toString().trim() ===
                v[1].toDate().toString().trim())
          ) {
            setIsHoursAvailable(true);
          } else {
            setIsHoursAvailable(false);
            setHours(['00:00', '20:59']);
          }
        }}
        calendars={2}
      />
      <Stack style={{ width: '100%', background: 'white' }} pb={2} mt={-2}>
        <Box ml={20} style={{ fontFamily: 'Roboto' }}>
          <Stack direction="row" alignItems="center" gap={2} mt={3}>
            {isHourPicker && (
              <>
                <AcSelect
                  header="From"
                  disabled={!isHoursAvailable && !isHourPickerInDays}
                  sx={{ width: 100 }}
                  size="small"
                  value={hours[0]}
                  onChange={(e) =>
                    setHours((oldValues) => [
                      e.target.value as string,
                      oldValues[1]
                    ])
                  }
                  items={[
                    {
                      content: '00:00',
                      key: '00:00',
                      value: '00:00'
                    },
                    {
                      content: '01:00',
                      key: '01:00',
                      value: '01:00'
                    },
                    {
                      content: '02:00',
                      key: '02:00',
                      value: '02:00'
                    },
                    {
                      content: '03:00',
                      key: '03:00',
                      value: '03:00'
                    },
                    {
                      content: '04:00',
                      key: '04:00',
                      value: '04:00'
                    },
                    {
                      content: '05:00',
                      key: '05:00',
                      value: '05:00'
                    },
                    {
                      content: '06:00',
                      key: '06:00',
                      value: '06:00'
                    },
                    {
                      content: '07:00',
                      key: '07:00',
                      value: '07:00'
                    },
                    {
                      content: '08:00',
                      key: '08:00',
                      value: '08:00'
                    },
                    {
                      content: '09:00',
                      key: '09:00',
                      value: '09:00'
                    },
                    {
                      content: '10:00',
                      key: '10:00',
                      value: '10:00'
                    },
                    {
                      content: '11:00',
                      key: '11:00',
                      value: '11:00'
                    },
                    {
                      content: '12:00',
                      key: '12:00',
                      value: '12:00'
                    },
                    {
                      content: '13:00',
                      key: '13:00',
                      value: '13:00'
                    },
                    {
                      content: '14:00',
                      key: '14:00',
                      value: '14:00'
                    },
                    {
                      content: '15:00',
                      key: '15:00',
                      value: '15:00'
                    },
                    {
                      content: '16:00',
                      key: '16:00',
                      value: '16:00'
                    },
                    {
                      content: '17:00',
                      key: '17:00',
                      value: '17:00'
                    },
                    {
                      content: '18:00',
                      key: '18:00',
                      value: '18:00'
                    },
                    {
                      content: '19:00',
                      key: '19:00',
                      value: '19:00'
                    },
                    {
                      content: '20:00',
                      key: '20:00',
                      value: '20:00'
                    },
                    {
                      content: '21:00',
                      key: '21:00',
                      value: '21:00'
                    },
                    {
                      content: '22:00',
                      key: '22:00',
                      value: '22:00'
                    },
                    {
                      content: '23:00',
                      key: '23:00',
                      value: '23:00'
                    }
                  ]}
                />
                <Box mt={1.5}>
                  <span style={{ fontSize: 18 }}>—</span>
                </Box>
                <AcSelect
                  header="To"
                  disabled={!isHoursAvailable && !isHourPickerInDays}
                  sx={{ width: 100 }}
                  size="small"
                  value={hours[1]}
                  onChange={(e) =>
                    setHours((oldValues) => [
                      oldValues[0],
                      e.target.value as string
                    ])
                  }
                  items={[
                    {
                      content: '00:00',
                      key: '00:00',
                      value: '00:00'
                    },
                    {
                      content: '01:00',
                      key: '01:00',
                      value: '01:00'
                    },
                    {
                      content: '02:00',
                      key: '02:00',
                      value: '02:00'
                    },
                    {
                      content: '03:00',
                      key: '03:00',
                      value: '03:00'
                    },
                    {
                      content: '04:00',
                      key: '04:00',
                      value: '04:00'
                    },
                    {
                      content: '05:00',
                      key: '05:00',
                      value: '05:00'
                    },
                    {
                      content: '06:00',
                      key: '06:00',
                      value: '06:00'
                    },
                    {
                      content: '07:00',
                      key: '07:00',
                      value: '07:00'
                    },
                    {
                      content: '08:00',
                      key: '08:00',
                      value: '08:00'
                    },
                    {
                      content: '09:00',
                      key: '09:00',
                      value: '09:00'
                    },
                    {
                      content: '10:00',
                      key: '10:00',
                      value: '10:00'
                    },
                    {
                      content: '11:00',
                      key: '11:00',
                      value: '11:00'
                    },
                    {
                      content: '12:00',
                      key: '12:00',
                      value: '12:00'
                    },
                    {
                      content: '13:00',
                      key: '13:00',
                      value: '13:00'
                    },
                    {
                      content: '14:00',
                      key: '14:00',
                      value: '14:00'
                    },
                    {
                      content: '15:00',
                      key: '15:00',
                      value: '15:00'
                    },
                    {
                      content: '16:00',
                      key: '16:00',
                      value: '16:00'
                    },
                    {
                      content: '17:00',
                      key: '17:00',
                      value: '17:00'
                    },
                    {
                      content: '18:00',
                      key: '18:00',
                      value: '18:00'
                    },
                    {
                      content: '19:00',
                      key: '19:00',
                      value: '19:00'
                    },
                    {
                      content: '20:00',
                      key: '20:00',
                      value: '20:00'
                    },
                    {
                      content: '21:00',
                      key: '21:00',
                      value: '21:00'
                    },
                    {
                      content: '22:00',
                      key: '22:00',
                      value: '22:00'
                    },
                    {
                      content: '23:59',
                      key: '23:59',
                      value: '23:59'
                    }
                  ]}
                />
                {duration !== null && isHoursAvailable && !disableFuture && (
                  <Grid size={2} sx={{ ml: 2 }}>
                    <AcInput value={duration} header={'Duration'} disabled />
                  </Grid>
                )}
              </>
            )}
          </Stack>
          {errorMessage && (
            <Box mt={2} mb={1}>
              <Alert severity="error">{errorMessage}</Alert>
            </Box>
          )}
          {!disableFuture && (
            <Grid size={7.6}>
              <AcInput
                header="Notes"
                name="notes"
                placeholder="Type"
                textarea
                sx={{
                  '& .MuiInputBase-input': {
                    padding: 0
                  }
                }}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Grid>
          )}
        </Box>
        <Stack direction="row" justifyContent="flex-end" gap={1} pt={1}>
          <ActionButton
            variant="outlined"
            text={'Close'}
            onClick={() => onClose()}
          />
          <ActionButton
            text={onApplyButtonText}
            disabled={
              isApplyDisabled ||
              dayjs(hours[0], 'HH:mm').isAfter(dayjs(hours[1], 'HH:mm')) ||
              !!errorMessage
            }
            onClick={() =>
              onApply(dateRange, isHoursAvailable ? hours : undefined, notes)
            }
          />
        </Stack>
      </Stack>
    </LocalizationProvider>
  );
}

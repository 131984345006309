import { useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';
import useCoupons from 'api/useCoupons';
import { EPermissionAction, EPermissionGroup } from 'common/permissions.types';
import dayjs from 'dayjs';
import { StatusLabel } from 'design-system/StatusLabel/StatusLabel';
import { EStatusLabel } from 'design-system/StatusLabel/types';

import AcContentWrapper from '../../components/AcContentWrapper/AcContentWrapper';
import AcViewWrapper from '../../components/AcViewWrapper/AcViewWrapper';
import DataTable from '../../components/DataTable/DataTable';
import FirstActionModal from '../../components/FirstActionModal/FirstActionModal';
import PageTopBar from '../../components/Topbar/PageTopBar';
import { DATE_TIME_FORMAT } from '../../constants/constants';
import { ELocalStorageKeys, ENotificationType } from '../../constants/enums';
import { useNotifications } from '../../hooks/useNotifications';
import { AuthSliceState } from '../../store/store.types';
import { permissionsUtil } from '../../utils/permissionsUtil';

const CouponsTable = () => {
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const navigate = useNavigate();
  const { getCoupons, setCouponActive } = useCoupons(currentPublisherId);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedCouponId, setSelectedCouponId] = useState<string | null>(null);

  const { enqueueSnackbar } = useNotifications();

  const toggleActivateCoupon = async (couponId: string, active: boolean) => {
    setCouponActive.mutate(
      {
        couponId,
        active
      },
      {
        onSuccess: () => {
          enqueueSnackbar(
            `Coupon ${active ? 'activated' : 'deactivated'} successfully`,
            ENotificationType.SUCCESS
          );
          getCoupons.refetch();
        },
        onError: (error: any) => {
          enqueueSnackbar(
            `Failed to ${active ? 'activate' : 'deactivate'} coupon`,
            ENotificationType.ERROR,
            error.message || ''
          );
        }
      }
    );
  };

  const handleDeleteCoupon = async (couponId: string) => {};

  const columns = [
    {
      field: 'id',
      flex: 1,
      headerName: 'ID',
      width: 100
    },
    {
      field: 'name',
      flex: 1,
      headerName: 'Name',
      width: 100
    },
    {
      field: 'discountPercentage',
      flex: 1,
      headerName: 'Discount',
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        return <span>{params.value}%</span>;
      }
    },
    // {
    //   field: 'redemptions',
    //   flex: 1,
    //   headerName: 'Redemptions',
    //   width: 100,
    //   renderCell: (params: GridRenderCellParams) => {
    //     return (
    //       <span>
    //         {params.row.currentRedemptions}/{params.row.maxRedemptions}
    //       </span>
    //     );
    //   }
    // },
    {
      field: 'expiredBy',
      flex: 1,
      headerName: 'Expires on',
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <span>
            {params.value
              ? dayjs(params.value).format(DATE_TIME_FORMAT)
              : 'Never'}
          </span>
        );
      }
    },
    {
      field: 'createdAt',
      flex: 1,
      headerName: 'Created at',
      width: 200,
      type: 'date',
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) return <span>N/A</span>;
        return <span>{dayjs(params.value).format(DATE_TIME_FORMAT)}</span>;
      }
    },
    {
      field: 'active',
      flex: 1,
      headerName: 'Status',
      width: 200,
      renderCell: ({ value }: any) => {
        return (
          <StatusLabel
            text={!!value ? 'Active' : 'Inactive'}
            status={!!value ? EStatusLabel.SUCCESS : EStatusLabel.FAILED}
          />
        );
      }
    },
    {
      field: 'actions',
      flex: 0,
      type: 'actions',
      width: 80,
      disableReorder: true,

      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<EditTwoToneIcon />}
          label="Edit"
          onClick={() => {
            navigate(`./form/${params.row.id}`);
          }}
          disabled={
            !permissionsUtil.canUserEdit() ||
            (permissionsUtil.isNewVersion() &&
              !permissionsUtil.isActionEnabled(
                null,
                EPermissionGroup.BUNDLES,
                currentPublisherId,
                EPermissionAction.MANAGE
              ))
          }
          showInMenu
        />,
        <GridActionsCellItem
          icon={
            params.row.active ? (
              <DoNotDisturbIcon />
            ) : (
              <CheckCircleOutlineIcon />
            )
          }
          label={params.row.active ? 'Deactivate' : 'Activate'}
          onClick={() => {
            toggleActivateCoupon(params.row.id, !params.row.active);
          }}
          disabled={
            !permissionsUtil.canUserEdit() ||
            (permissionsUtil.isNewVersion() &&
              !permissionsUtil.isActionEnabled(
                null,
                EPermissionGroup.BUNDLES,
                currentPublisherId,
                EPermissionAction.MANAGE
              ))
          }
          showInMenu
        />
      ]
    }
  ];

  return (
    <>
      <AcViewWrapper
        header={
          <PageTopBar
            headline="Coupons"
            buttons={[
              {
                text: 'Add New Coupon',
                action: () => navigate('./form'),
                disabled: !permissionsUtil.canUserEdit(),
                hidden:
                  permissionsUtil.isNewVersion() &&
                  !permissionsUtil.isActionEnabled(
                    null,
                    EPermissionGroup.BUNDLES,
                    currentPublisherId,
                    EPermissionAction.MANAGE
                  )
              }
            ]}
          />
        }
      >
        <AcContentWrapper>
          <DataTable
            columns={columns}
            rows={getCoupons?.data || []}
            loading={getCoupons.isLoading || getCoupons.isFetching}
            defaultHiddenFields={['id']}
            localStorageColumnsKey={ELocalStorageKeys.ORDERS_COLUMN_VISIBILITY}
            hideFooter={false}
            rowIdIdentifier="id"
            error={false}
            onNoData={
              <FirstActionModal
                headline="Create your first coupon"
                text="Hit the “Add new coupon” button on the top right corner of the screen to get started"
              />
            }
          />
        </AcContentWrapper>
      </AcViewWrapper>
    </>
  );
};

export default CouponsTable;

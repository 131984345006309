import React, { useMemo, useState } from 'react';

import Grid from '@mui/material/Grid2';
import { Stack } from '@mui/system';
import useLocalization from 'api/useLocalization';
import { ENotificationType } from 'constants/enums';
import { useNotifications } from 'hooks/useNotifications';
import countries from 'i18n-iso-countries';
import {
  DELETE_LANGUAGE_SUCCESS_MSG,
  EDIT_LANGUAGE_SUCCESS_MSG
} from 'views/Localization/constants/constants';

import AcCard from 'components/AcCard/AcCard';

import { LanguageDataParams } from '../types';

import AddedLanguagesTable from './components/AddedLanguagesTable/AddedLanguagesTable';
import { LanguageTableRow } from './components/AddedLanguagesTable/AddedLanguagesTable.types';
import DeleteLanguageModal from './components/DeleteLanguageModal/DeleteLanguageModal';
import EditLanguageModal from './components/EditLanguageModal/EditLanguageModal';
import ManageLanguageForm from './components/ManageLanguageForm/ManageLanguageForm';

import './style.scss';

const LanguagesListSection = () => {
    const [isManageLngModalOpen, setIsManageLngModalOpen] = useState(false);
    const [isDeleteLngModalOpen, setIsDeleteLngModalOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState<LanguageTableRow | null>(null);
    const { getAddedLanguagesList, editLanguage, deleteLanguage, addNewLanguage} = useLocalization();
    const { enqueueSnackbar } = useNotifications();
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    const countriesList = countries.getNames('en');

    const handleAddNewLanguage = (data: LanguageDataParams[]) => {
        addNewLanguage.mutate(data, {
            onSuccess: () => {
                getAddedLanguagesList.refetch();
                enqueueSnackbar(
                    "New language was added successfully",
                    ENotificationType.SUCCESS
                );
            },
            onError: (err: any) => {
                enqueueSnackbar(
                    err?.response?.data?.message ||
                    "New language was not added. Please try again",
                    ENotificationType.SUCCESS
                );
            }
        })
    }

    const editSelectedLanguage = (updatedLanguagesList: LanguageDataParams[]) => (
        editLanguage.mutate(updatedLanguagesList, {
            onSuccess: () => {
                getAddedLanguagesList.refetch();
                enqueueSnackbar(
                    EDIT_LANGUAGE_SUCCESS_MSG,
                    ENotificationType.SUCCESS
                );
            },
            onError: (data: any) => {
                enqueueSnackbar(data.response.data.message, ENotificationType.ERROR);
            }
        })
    );

    const handleEditLanguageSelection = (data: LanguageDataParams[]) => {
        if (!selectedLanguage) return;
        let updatedLanguagesList = getAddedLanguagesList.data.filter((data: LanguageDataParams) => data.language !== 'en');

        updatedLanguagesList = updatedLanguagesList.filter((element: LanguageDataParams) => element.language !== selectedLanguage.language);

        data.forEach((item) => {
            updatedLanguagesList.push(item)
        })

        setIsManageLngModalOpen(false);
        setSelectedLanguage(null);
        editSelectedLanguage(updatedLanguagesList)
    }

    const handleDeleteLanguage = () => {
        const updatedLanguagesList = getAddedLanguagesList.data.filter((item: LanguageDataParams) => item.language !== selectedLanguage?.language)

        setIsDeleteLngModalOpen(false);
        setSelectedLanguage(null);
        deleteLanguage.mutate(updatedLanguagesList, {
            onSuccess: () => {
                getAddedLanguagesList.refetch();
                enqueueSnackbar(
                    DELETE_LANGUAGE_SUCCESS_MSG,
                    ENotificationType.SUCCESS
                );
            },
            onError: (data: any) => {
                enqueueSnackbar(data.response.data.message, ENotificationType.ERROR);
            }
        },
        )
    }

    const parsedLanguagesList = useMemo(() => {
        if (!getAddedLanguagesList.data) return [];
        return getAddedLanguagesList.data.filter((item: LanguageDataParams) => item.language !== 'English').map((language: LanguageDataParams) => {
            const countryName = Object.entries(countriesList).find(([key]) => key === language.country)?.[1];
            return {...language, country: countryName, countryCode: language.country}
        })
    },[getAddedLanguagesList.data, countriesList])

    return (
        <Grid container sx={{ marginTop: '22px' }}>
            <Grid size={12}>
                {
                    <AcCard
                        title={"1. Manage Languages"}
                        description={"Choose the language display name and define the countries where this language will be applied for users"}
                        stackContainer={false}
                        className="languagesList"
                        customDescriptionClass="languagesListdDescription"
                        customTitleClass="sectionTitle"
                        customStyles={{"border": "1px solid #cacbd4", "borderRadius": "4px"}}
                    >
                        <Stack>
                            <Grid container direction="column">
                                <Grid>
                                   <ManageLanguageForm
                                       countriesList={countriesList}
                                       selectedLanguage={selectedLanguage}
                                       onSave={handleAddNewLanguage}
                                   />
                                </Grid>
                                <Grid>
                                    <AddedLanguagesTable
                                        languagesList={parsedLanguagesList}
                                        isLoading={getAddedLanguagesList?.isLoading}
                                        selectedLanguage={selectedLanguage}
                                        setIsDeleteLngModalOpen={setIsDeleteLngModalOpen}
                                        setSelectedLanguage={setSelectedLanguage}
                                        setIsManageLngModalOpen={setIsManageLngModalOpen}
                                        editSelectedLanguage={editSelectedLanguage}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </AcCard>
                }
            </Grid>
            <EditLanguageModal
                currentLanguage={selectedLanguage}
                countriesList={countriesList}
                isOpen={isManageLngModalOpen}
                onClose={() => {
                    setIsManageLngModalOpen(false);
                    setSelectedLanguage(null)
                }}
                onSave={(data: LanguageDataParams[]) => handleEditLanguageSelection(data)}
            />
            <DeleteLanguageModal
                isOpen={isDeleteLngModalOpen}
                setIsDeleteModalOpen={setIsDeleteLngModalOpen}
                handleDeleteLanguage={handleDeleteLanguage}
                handleSelectedLanguage={setSelectedLanguage}
            />
        </Grid>
    )
}

export default LanguagesListSection;

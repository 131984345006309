export enum ELocalStorageKeys {
  JWT_TOKEN = 'jwt_token',
  JWT_TOKEN_TTL = 'jwt_token_ttl',
  USER_DETAILS = 'user_details',
  BUNDLES_COLUMN_VISIBILITY = 'bundlesColumnVisibility',
  ORDERS_COLUMN_VISIBILITY = 'columnVisibility',
  PRODUCTS_COLUMN_VISIBILITY = 'productsColumnVisibility',
  TAGS_COLUMN_VISIBILITY = 'tagsColumnVisibility',
  ENVIRONMENT = 'env',
  MOCK_API = 'mock_api',
  FEATURE_FLAGS = 'feature_flags',
  OVERRIDE_STATS = 'override_stats',
  OVERRIDE_TRENDS = 'override_trends',
  PUBLISHER_ID = 'publisher_id',
  PUBLISHER_SOLUTION_MODEL = 'publisher_solution_model',
  PERMISSIONS = 'permissions'
}

export enum ENotificationType {
  ERROR = 'error',
  WARNING = 'warring',
  INFO = 'info',
  SUCCESS = 'success'
}

export enum EQueryKeys {
  COUPONS = 'coupons',
  SINGLE_COUPON = 'single_coupon',
  BUNDLES = 'bundles',
  ORDERS = 'orders',
  ORDER_DETAILS = 'order_details',
  PRODUCTS = 'products',
  PRODUCT = 'product',
  TAGS = 'tags',
  USERS = 'users',
  IMAGES = 'images',
  INTEGRATION = 'integration',
  PUBLISHER = 'publisher',
  PUBLISHER_SETTINGS = 'publisher_settings',
  PLAYERS = 'players',
  USERS_BY_PUBLISHER = 'users_by_publisher',
  SEGMENTS = 'segments',
  PUBLISHER_TAGS = 'offer-tags',
  GRAPHS = 'graphs',
  BARS = 'bars',
  PIES = 'pies',
  FLAT_STATS = 'flat_stats',
  STORE_THEME = 'store_theme',
  CHECKOUT_THEME = 'checkout_theme',
  ANALYTICS = 'analytics',
  PRICING = 'pricing',
  PAYMENTS = 'payments',
  PAYMENTS_CONFIG = 'payments_config',
  GEO = 'geo',
  REFUND = 'refund',
  OFFERS_UI = 'offers_ui',
  BADGES = 'badges',
  OFFERS = 'offers',
  TRENDS = 'trends',
  FINANCIAL_REPORTS = 'report',
  PAYOUTS = 'payouts',
  SECTIONS = 'sections',
  FEATURE_FLAGS = 'feature_flags',
  PRICING_POINTS_TEMPLATE = 'pricing_points_template',
  ROLES = 'roles',
  LOCALIZATION_ADDED_LANGUAGES_LIST = 'localization_added_languages_list',
  LOCALIZATION_STATIC_TRANSLATIONS_FILE = 'localization_static_translations_file',
  LOCALIZATION_DYNAMIC_TRANSLATIONS_FILE = 'localization_dynamic_translations_file',
  LOCALIZATION_STATIC_TRANSLATIONS_FILE_LAST_UPDATE = 'localization_static_translations_file_last_update',
  LOCALIZATION_DYNAMIC_TRANSLATIONS_FILE_LAST_UPDATE = 'localization_dynamic_translations_file_last_update'
}

export enum EAssetType {
  BG_BUNDLE = 'bgBundle',
  BG_DESKTOP = 'bgDesk',
  BG_MOBILE = 'bgMobile',
  BG_POPUP = 'bgPopup',
  FAVICON = 'favicon',
  LOGO = 'logo',
  BADGE = 'badge',
  PRODUCT = 'product',
  PRODUCT_PREFIX = 'productPrefix',
  GENERAL = 'general',
  SECTION = 'section',
  BANNER = 'banner'
}

export enum EBundlesInternalViewModel {
  AK = 'ak',
  MATCH = 'match',
  COMMUNIX = 'communix',
  MAIN_SUB = 'mainSub',
  MAIN_SUB_ALT = 'mainSubAlt',
  BAGELCODE = 'bagelcode',
  LOCO_BINGO = 'locobingo',
  JACKPOT_SLOTS = 'jackpot-slots',
  BIG_FISH_CASINO = 'big-fish-casino',
  CASHMAN_CASINO = 'cashman-casino',
  LIGHTNING_LINK_CASINO = 'lightning-link-casino',
  HEART_OF_VEGAS = 'heart-of-vegas',
  ME2ON = 'me2on',
  PLAY_SIMPLE = 'play-simple',
  PIPA = 'pipa',
  WHISPERS = 'whispers',
  BABA = 'baba'
}

export enum BannerAspectRatio {
  SQUARE = '1 / 1',
  TWO_ON_THREE = '2 / 3'
}

export enum ESpecialOfferInternalViewModel {
  HUGE = 'hug',
  MATCH_MASTERS = 'match-masters',
  POKER_FACE = 'pokerFace',
  BAGELCODE = 'bagelcode',
  LOCO_BINGO = 'locobingo',
  ME2ON = 'me2on',
  PIPA = 'pipa',
  BABA = 'baba',
  JACKPOT_SLOTS = 'jackpot-slots',
  BIG_FISH_CASINO = 'big-fish-casino',
  PLAY_SIMPLE = 'play-simple'
}

export enum ELogoSize {
  SMALL = 'small',
  BIG = 'big'
}

export enum EProductPriority {
  MAIN = 'Main',
  SUB = 'Sub'
}

export enum EProductType {
  QUANTITY = 'Quantity',
  TIME = 'Time'
}

export enum EFeatureFlag {
  DISPLAY_NEW_BUNDLES = 'displayNewBundles',
  DISPLAY_NEW_PRODUCTS = 'displayNewProducts',
  DASHBOARD_OFFERS_FREE = 'dashboard_offers_free',
  DASHBOARD_PAYOUTS_CENTER = 'dashboard_payouts_center',
  DASHBOARD_FINANCIAL_REPORTS = 'dashboard_financial_reports',
  DASHBOARD_DISPUTE_EVIDENCES = 'dashboard_dispute_evidences',
  DASHBOARD_CHECKOUT_BUILDER = 'dashboard_checkout_builder',
  DASHBOARD_POPUP = 'dashboard_popup',
  DASHBOARD_POST_PURCHASE = 'dashboard_post_purchase',
  DASHBOARD_TAGS = 'dashboard_tags',
  DASHBOARD_COUPONS = 'dashboard_coupons',
  DASHBOARD_DAILY_BONUS_BADGE = 'dashboard_daily_bonus_badge',
  DASHBOARD_ADD_TO_HOME_SCREEN = 'dashboard_add_to_home_screen',
  DASHBOARD_SCHEDULED_OFFERS = 'dashboard_scheduled_offers',
  STORE_MULTI_LANGUAGE_SUPPORT = 'store_multi_language_support',
  DASHBOARD_BANNERS = 'dashboard_banners'
}

export enum EStyleProperty {
  fontWeight = 'fontWeight',
  fontFamily = 'fontFamily'
}

export enum EFontWeight {
  Regular = 'regular',
  Medium = 'medium',
  Bold = 'bold'
}

export enum SortingOrderValue {
  STATUS = 'status',
  BUNDLE = 'bundle',
  ID = 'id',
  PROVIDER = 'provider',
  AMOUNT = 'amount',
  PLAYER = 'player',
  DATE = 'date'
}

export enum SortingOffersValue {
  ID = 'id',
  PUBLISHER_OFFER_ID = 'publisher_offer_id',
  NAME = 'name',
  CREATED_AT = 'created_at',
  PRICE = 'price'
}

export enum SortingDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export enum OfferType {
  BUNDLE = 'Bundle',
  SPECIAL_OFFER = 'SpecialOffer',
  ONE_PLUS_ONE = 'OnePlusOne',
  BONUS = 'Bonus',
  POPUP = 'PopUp'
}

export enum GridFeatureMode {
  CLIENT = 'client',
  SERVER = 'server'
}

export enum EOrderStatus {
  unpaid = 'unpaid',
  created = 'created',
  payment_pending = 'payment_pending',
  payment_succeed = 'payment_succeed',
  payment_failed = 'payment_failed',
  payment_refunded = 'payment_refunded',
  payment_refund_failed = 'payment_refund_failed',
  payment_refund_cancelled = 'payment_refund_cancelled',
  payment_canceled = 'payment_canceled',
  charge_pending = 'charge_pending',
  charge_succeed = 'charge_succeed',
  charge_failed = 'charge_failed',
  payment_refund_pending = 'payment_refund_pending',
  payment_dispute_open = 'payment_dispute_open',
  payment_dispute_lost = 'payment_dispute_lost',
  payment_dispute_won = 'payment_dispute_won',
  payment_dispute_evidence_submitted = 'payment_dispute_evidence_submitted',
  payment_dispute_inquiry_open = 'payment_dispute_inquiry_open',
  payment_dispute_inquiry_evidence_submitted = 'payment_dispute_inquiry_evidence_submitted',
  payment_dispute_inquiry_won = 'payment_dispute_inquiry_won'
}

export enum EBalancesTheme {
  DEFAULT = 'default',
  BAGELCODE = 'bagelcode',
  SPACEGO = 'spacego',
  PLAYSIMPLE = 'play-simple',
  ME2ON = 'me2on',
  PIPA = 'pipa',
  WHISPERS = 'whispers'
}

export enum ESectionViewModel {
  DEFAULT = 'default',
  BAGELCODE = 'bagelcode',
  PIPA = 'pipa',
  WHISPERS = 'whispers',
  LLC = 'llc'
}

export enum EActiveState {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}

export enum ESetting {
  ROUNDING_ON = 'rounding-on',
  TAX_ON = 'tax-on'
}

export enum ESaleDiscountDisplayType {
  PERCENTAGE = 'percentage',
  MULTIPLIER = 'multiplier',
  FIXED_AMOUNT = 'fixed_amount'
}

export enum EPopupSubType {
  DAILY_BONUS = 'DailyBonus',
  POST_PURCHASE = 'PostPurchase'
}

export enum EPopupsFormState {
  GENERAL = 'general',
  PRODUCTS = 'products',
  TRIGGERS = 'triggers'
}

export enum EPromotionsFormState {
  GENERAL = 'general',
  SCHEDULE = 'schedule'
}

export enum EAppearanceStatus {
  UPCOMING = 'Upcoming',
  RUNNING = 'Running',
  ENDED = 'Ended'
}

export enum EPendingAppearanceStatus {
  PENDING_SAVE = 'Pending Save'
}

export enum EScheduleType {
  PERMANENT = 'Permanent',
  SCHEDULED = 'Scheduled'
}

export enum EButtonColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning'
}

export enum EButtonVariant {
  TEXT = 'text',
  OUTLINED = 'outlined',
  CONTAINED = 'contained'
}

export enum UploadTypes {
  STATIC = 'static',
  DYNAMIC = 'dynamic'
}

export enum ELogoutMessage {
  OUTDATED_VERSION = 'Outdated version'
}
